import Header from './components/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Navbar from './components/Navbar';
import LayoutSetting from './components/LayoutSetting';
import Dashboard from './pages/Dashboard';
import Page404 from './pages/Page404';
import Login from './pages/Login';
import Users from './pages/Users';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Appointments from './pages/Appointments';
import Partners from './pages/Partners';
import Partner from './pages/Partner';
import Subscribers from './pages/Subscribers';
import Propdesks from './pages/Propdesks';
import Stock from './pages/Stock';
import Home from './pages/Home';

function App() {
  return (
    <div className="GleamDesk-admin">
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <Navbar />
        <LayoutSetting />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/appointments" element={<Appointments />} />
          <Route exact path="/partners" element={<Partners />} />
          <Route exact path="/partner/:id" element={<Partner />} />
          <Route exact path="/subscribers" element={<Subscribers />} />
          <Route exact path="/propdesks" element={<Propdesks />} />
          <Route exact path="/stock" element={<Stock />} />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
