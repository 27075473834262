import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import authContext from '../context/authContext/authContext';
import dataContext from '../context/dataContext/dataContext';

const Header = () => {
  const { pathname } = useLocation();
  const { user, logout } = useContext(authContext)
  const { getAllowedPages } = useContext(dataContext)

  const pages = getAllowedPages(pathname);
  if (!pages.includes(pathname)) {
    return;
  }

  const handleLogout = () => {
    logout();
    return;
  }

  return (
    <div className="header">
      <div className="header-left">
        <div className="menu-icon dw dw-menu" />
        <div
          className="search-toggle-icon dw dw-search2"
          data-toggle="header_search"
        />
        <div className="header-search">
          <form>
            <div className="form-group mb-0">
              <i className="dw dw-search2 search-icon" />
              <input
                type="text"
                className="form-control search-input"
                placeholder="Search Here"
              />
              <div className="dropdown">
                <a
                  className="dropdown-toggle no-arrow"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <i className="ion-arrow-down-c" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">
                      From
                    </label>
                    <div className="col-sm-12 col-md-10">
                      <input
                        className="form-control form-control-sm form-control-line"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">To</label>
                    <div className="col-sm-12 col-md-10">
                      <input
                        className="form-control form-control-sm form-control-line"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">
                      Subject
                    </label>
                    <div className="col-sm-12 col-md-10">
                      <input
                        className="form-control form-control-sm form-control-line"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="text-right">
                    <button className="btn btn-primary">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="header-right">
        <div className="dashboard-setting user-notification">
          <div className="dropdown">
            <a
              className="dropdown-toggle no-arrow"
              href="javascript:;"
              data-toggle="right-sidebar"
            >
              <i className="dw dw-settings2" />
            </a>
          </div>
        </div>

        <div className="user-info-dropdown">
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
            >
              <span className="user-icon">
                <img src={user?.image || "assets/images/photo1.jpg"} alt="" />
              </span>
              <span className="user-name text-capitalize">{user?.name}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
              <a className="dropdown-item" href={process.env.REACT_APP_GLEAMDESK_URL + '/profile'} target='_blank'>
                <i className="dw dw-user1" /> Profile
              </a>
              <a className="dropdown-item" onClick={handleLogout} href="/login">
                <i className="dw dw-logout" /> Log Out
              </a>
            </div>
          </div>
        </div>
        <div className="github-link">
          <a href={process.env.REACT_APP_GLEAMDESK_URL} target="_blank">
            <img src="assets/images/gleamDesk.png" style={{ height: '100%' }} alt="" />
          </a>
        </div>
      </div>
    </div>

  )
}

export default Header