import React, { useContext } from 'react'
import authContext from '../context/authContext/authContext'

const Home = () => {
  const { user } = useContext(authContext)
  if (user) {
    window.location.href = '/dashboard';
  } else {
    window.location.href = '/login';
  }
  return (
    <div>Home</div>
  )
}

export default Home