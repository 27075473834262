import React, { useContext, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import { toast } from 'react-toastify';

import authContext from '../../context/authContext/authContext';
import dataContext from '../../context/dataContext/dataContext';
import errorHandle from '../../utils/errorHandle';

const ADD_PROPDESK_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/addPropdesk";

const AddPropdesk = ({ isOpen, toggle, getPropdesks, YEARS_ARRAY, END_YEAR }) => {
  const { getConfig } = useContext(authContext);
  const { MONTHS_LIST } = useContext(dataContext)

  const intialState = { loading: false, month: MONTHS_LIST[new Date().getMonth()], year: END_YEAR, percentage: 0.5, status: 'Up', percentageError: '', addPropdeskError: '' }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const onHideModal = () => {
    setState(intialState)
    toggle();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    AddPropdesk();
  }

  const validatePercentage = () => {
    if (!state.percentage) {
      setState({ percentageError: '*Please enter percentage' });
    } else if (state.percentage < 0) {
      setState({ percentageError: '*Please enter valid percentage' });
    }
  }

  const AddPropdesk = async () => {
    setState({ loading: true })
    const { month, year, percentage, status, percentageError, addPropdeskError } = state
    const isValid = month && year && percentage && status && (percentageError === intialState.percentageError) && (addPropdeskError === intialState.addPropdeskError);
    if (!isValid) {
      setState({ addPropdeskError: '*Please fill with valid data!', loading: false });
      return;
    }

    const data = { month, year, percentage, status }
    const config = getConfig();
    await axios.post(ADD_PROPDESK_API_END_POINT, data, config)
      .then((res) => {
        setState({ loading: false })
        toast.success(res?.data?.message, { position: 'bottom-right' });
        onHideModal();
        getPropdesks();
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const closeBtn = () => <button className='btn btn-close' onClick={onHideModal}>X</button>;

  return (
    <Modal isOpen={isOpen} toggle={onHideModal}>
      <ModalHeader toggle={onHideModal} close={closeBtn()}>
        <div className='d-flex align-items-center'>
          <div>Add Propdesk</div>
          <div className='ml-3'>
            {state.loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div class="form-group1">
                <label>Month</label>
                <select class="form-select custom-select" aria-label="Default select example" onChange={(e) => setState({ month: e.target.value })} disabled={state.loading}>
                  {MONTHS_LIST.map((month) => {
                    return (
                      <option value={month} selected={month === (state?.month)}>{month}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div class="form-group">
                <label>Year</label>
                <select class="form-select custom-select" aria-label="Default select example" onChange={(e) => setState({ year: e.target.value })} disabled={state.loading}>
                  {(YEARS_ARRAY || []).map((year) => {
                    return (
                      <option value={year} selected={year === (state?.year)}>{year}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div class="form-group">
                <label>Percentage</label>
                <input
                  type="number"
                  class="form-control"
                  value={state?.percentage}
                  min={0}
                  onChange={(e) => setState({ percentage: e.target.value })}
                  onBlur={validatePercentage}
                  onFocus={() => setState({ percentageError: '', addPropdeskError: '' })}
                  disabled={state.loading}
                />
                {state.percentageError ? <div className='text-danger'>{state.percentageError}</div> : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div class="form-group">
                <label>Status</label>
                <select class="form-select custom-select" aria-label="Default select example" onChange={(e) => setState({ status: e.target.value })} disabled={state.loading}>
                  <option value="Up" selected={state?.status === 'Up'}>Up</option>
                  <option value="Down" selected={state?.status === 'Down'}>Down</option>
                </select>
              </div>
            </div>
          </div>
          {state?.addPropdeskError ? <div className="text-center text-danger">{state?.addPropdeskError}</div> : null}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHideModal} disabled={state.loading}>
          Cancel
        </Button>
        <Button color="gleamdesk-blue gleamdesk-orange" onClick={handleSubmit} disabled={state.loading}>
          Add Propdesk
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddPropdesk