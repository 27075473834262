import axios from 'axios'

import constants from "../constants";
const { GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR, EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_ERROR, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR } = constants;

const GET_USERS_API_URL = process.env.REACT_APP_API_URL + "/api/admin/getUsers";
const DELETE_USER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deleteUser";
const USER_UPDATE_ENDPOINT = process.env.REACT_APP_API_URL + "/api/admin/updateUser";

export const getUsersAction = (config) => async (dispatch) => {
  dispatch({ type: GET_USERS })
  await axios.get(GET_USERS_API_URL, config)
    .then((res) => dispatch({ type: GET_USERS_SUCCESS, users: res?.data?.users }))
    .catch((error) => dispatch({ type: GET_USERS_ERROR, error }))
}

export const editUserAction = (update, config, callback1, callback2) => async (dispatch) => {
  dispatch({ type: EDIT_USER })
  await axios.post(USER_UPDATE_ENDPOINT, { update }, config)
    .then((res) => {
      dispatch({ type: EDIT_USER_SUCCESS, message: res?.data?.message })
      callback1();
      callback2();
    })
    .catch((error) => dispatch({ type: EDIT_USER_ERROR, error }))
}

export const deleteUserAction = (data, config, callback) => async (dispatch) => {
  dispatch({ type: DELETE_USER })
  await axios.post(DELETE_USER_API_END_POINT, data, config)
    .then((res) => {
      dispatch({ type: DELETE_USER_SUCCESS, message: res?.data?.message })
      callback();
    })
    .catch((error) => dispatch({ type: DELETE_USER_ERROR, error }))
}
