import React, { useState } from 'react'
import { AgChartsReact } from 'ag-charts-react';

const PropdesksChart = ({ data }) => {
  const chartData = {
    data: data,
    series: [{ type: 'bar', xKey: 'month', yKey: 'percentage' }],
  }

  return (
    <AgChartsReact options={chartData} />
  );
}

export default PropdesksChart;