import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import { toast } from 'react-toastify';
import authContext from '../../context/authContext/authContext';
import errorHandle from '../../utils/errorHandle';

const EDIT_PARTNER_ACCOUNT_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/editPartnerAccount";

const EditPartnerAccount = ({ isOpen, toggle, partner, getPartnerAccounts, accountId, accounts }) => {
  const { getConfig } = useContext(authContext);

  const intialState = { loading: false, nameError: '', editPartnerAccountError: '', account: {} }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    const account = (accounts || []).find((account) => account?._id === accountId);
    setState({ account: JSON.parse(JSON.stringify(account || {})) })
  }, [accountId, accounts]);

  const onHideModal = () => {
    setState(intialState)
    toggle();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    editAccount();
  }

  const validateName = () => {
    if (!state.account.name) {
      setState({ nameError: '*Please enter account holder name' });
    }
  }

  const editAccount = async () => {
    setState({ loading: true })
    const { nameError, editPartnerAccountError } = state
    const isValid = state?.account?.name && (nameError === intialState.nameError) && (editPartnerAccountError === intialState.editPartnerAccountError);
    if (!isValid) {
      setState({ editPartnerAccountError: '*Please fill all the required fields', loading: false });
      return;
    }

    const update = { ...state?.account };
    const config = getConfig();
    await axios.post(EDIT_PARTNER_ACCOUNT_API_END_POINT, { update }, config)
      .then((res) => {
        toast.success(res?.data?.message, { position: 'bottom-right' });
        onHideModal();
        getPartnerAccounts();
        setState({ loading: false })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }



  const updatePartneraAccountState = (key, value) => {
    const { account } = state || {};
    account[key] = value;
    setState({ account })
  }

  const closeBtn = () => <button className='btn btn-close' onClick={onHideModal}>X</button>;

  return (
    <Modal isOpen={isOpen} toggle={onHideModal} size='lg'>
      <ModalHeader toggle={onHideModal} close={closeBtn()}>
        <div className='d-flex align-items-center'>
          <div>Edit Partner Account</div>
          <div className='ml-3'>
            {state.loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              class="form-control"
              value={state?.account?.name}
              onChange={(e) => updatePartneraAccountState('name', e.target.value)}
              onBlur={validateName}
              onFocus={() => setState({ nameError: '', editPartnerAccountError: '' })}
              disabled={state.loading}
            />
            {state.nameError ? <div className='text-danger ml-2'>{state.nameError}</div> : null}
          </div>

          <div class="form-group">
            <label>Partner</label>
            <input
              type="text"
              class="form-control"
              value={partner?.name}
              disabled
            />
          </div>
          {state?.editPartnerAccountError ? <div className="text-center text-danger">{state?.editPartnerAccountError}</div> : null}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHideModal} disabled={state.loading}>
          Cancel
        </Button>
        <Button color="gleamdesk-blue gleamdesk-orange" onClick={handleSubmit} disabled={state.loading}>
          Update Account
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditPartnerAccount