import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import UserUpdateModal from '../components/modals/UserUpdateModal';
import authContext from '../context/authContext/authContext';
import errorHanle from '../utils/errorHandle';

import { deleteUserAction, getUsersAction } from '../redux/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const DELETE_USER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deleteUser";

const DEFAULT_USER_IMAGE = "assets/images/photo8.jpg";

const Users = () => {
  const { getConfig } = useContext(authContext)
  const config = getConfig()
  const dispatch = useDispatch();
  const { users, loading } = useSelector(state => state?.users)

  const intialState = { users: [] }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const getUsers = () => {
    dispatch(getUsersAction(config))
  }

  useEffect(() => {
    getUsers()
  }, []);

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        cofirmDeleteUser(id);
      }
    });
  }

  const cofirmDeleteUser = async (id) => {
    const data = { id }
    dispatch(deleteUserAction(data, config, getUsers))
  }

  return (
    <div className="main-container">
      <div className="card-box mb-30">
        <div className='d-flex align-items-center'>
          <div><h2 className="h4 pd-20">GleamDesk Users</h2></div>
          <div className='pt-2'>
            {loading ? (
              <div className='mb-4'>
                <div class="spinner-border text-primary" role="status">
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Address</th>
              <th scope="col">GD Tokens</th>
              <th scope="col">createdAt</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {(users || []).map((user) => {
              const { image, name, email, address, phone, gdTokens, _id, createdAt } = user;
              return (
                <tr>
                  <td scope="row"><img src={image || DEFAULT_USER_IMAGE} className='user-profile-image' alt="user image" /></td>
                  <th scope="row" className='name'>{name}</th>
                  <td scope="row">{email}</td>
                  <td scope="row">{phone || '-'}</td>
                  <td scope="row">{address || '-'}</td>
                  <td scope="row">{gdTokens}</td>
                  <td scope="row">{moment(createdAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row">
                    <button className='btn btn-secondary btn-sm my-1 mx-1' onClick={() => setState({ openUserUpdateModal: true, userId: _id })}>
                      <i className='fa fa-pencil' />
                    </button>
                    <button className='btn btn-danger btn-sm my-1 mx-1' onClick={() => deleteUser(_id)}>
                      <i className='fa fa-trash' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <UserUpdateModal
        isOpen={state.openUserUpdateModal}
        toggle={() => setState({ openUserUpdateModal: false, userId: null })}
        userId={state?.userId}
        users={users || []}
        getUsers={getUsers}
      />
    </div>
  )
}

export default Users