import axios from "axios";
import constants from "../constants";

const {
  GET_PARTNERS, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR,
  ADD_PARTNER, ADD_PARTNER_SUCCESS, ADD_PARTNER_ERROR,
  EDIT_PARTNER, EDIT_PARTNER_SUCCESS, EDIT_PARTNER_ERROR,
  DELETE_PARTNER, DELETE_PARTNER_SUCCESS, DELETE_PARTNER_ERROR
} = constants;

const GET_PARTNERS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getPartners";
const ADD_PARTNER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/addPartner";
const EDIT_PARTNER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/editPartner";
const DELETE_PARTNER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deletePartner";

export const getPartnersAction = (config) => async (dispatch) => {
  dispatch({ type: GET_PARTNERS })
  await axios.get(GET_PARTNERS_API_END_POINT, config)
    .then((res) => dispatch({ type: GET_PARTNERS_SUCCESS, partners: res?.data?.partners }))
    .catch((error) => dispatch({ type: GET_PARTNERS_ERROR, error }))
}

export const addPartnerAction = (data, config, callback1, callback2) => async (dispatch) => {
  dispatch({ type: ADD_PARTNER })
  await axios.post(ADD_PARTNER_API_END_POINT, data, config)
    .then((res) => {
      dispatch({ type: ADD_PARTNER_SUCCESS, message: res?.data?.message })
      callback1();
      callback2();
    })
    .catch((error) => dispatch({ type: ADD_PARTNER_ERROR, error }))
}

export const editPartnerAction = (update, config, callback1, callback2) => async (dispatch) => {
  dispatch({ type: EDIT_PARTNER })
  await axios.post(EDIT_PARTNER_API_END_POINT, { update }, config)
    .then((res) => {
      dispatch({ type: EDIT_PARTNER_SUCCESS, message: res?.data?.message })
      callback1();
      callback2();
    })
    .catch((error) => dispatch({ type: EDIT_PARTNER_ERROR, error }))
}

export const deletePartnerAction = (data, config, callback) => async (dispatch) => {
  dispatch({ type: DELETE_PARTNER })
  await axios.post(DELETE_PARTNER_API_END_POINT, data, config)
    .then((res) => {
      dispatch({ type: DELETE_PARTNER_SUCCESS, message: res?.data?.message })
      callback();
    })
    .catch((error) => dispatch({ type: DELETE_PARTNER_ERROR, error }))
}
