import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import authContext from '../context/authContext/authContext';
import AddPartnerAccount from '../components/modals/AddPartnerAccount';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import EditPartnerAccount from '../components/modals/EditPartnerAccount';
import Swal from 'sweetalert2';
import errorHandle from '../utils/errorHandle';

const GET_PARTNER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getPartner";
const GET_PARTNER_ACCOUNTS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getPartnerAccounts";
const DELETE_PARTNER_ACCOUNT_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deletePartnerAccount";

const Partner = () => {
  const { id } = useParams();
  const { getConfig } = useContext(authContext)

  const intialState = { loading: false, accounts: [], partner: {} }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const deleteAccount = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        cofirmDeleteAccount(id);
      }
    });
  }

  const cofirmDeleteAccount = async (id) => {
    setState({ loading: true })
    const data = { id }
    const config = getConfig()
    await axios.post(DELETE_PARTNER_ACCOUNT_API_END_POINT, data, config)
      .then((res) => {
        setState({ loading: false })
        toast.success(res?.data?.message, { position: 'bottom-right' });
        getPartnerAccounts();
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const getPartnerAccounts = async () => {
    setState({ loading: true })
    const config = getConfig();
    const data = { id };
    await axios.post(GET_PARTNER_ACCOUNTS_API_END_POINT, data, config)
      .then((res) => {
        setState({ accounts: res?.data?.accounts, loading: false })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const getPartner = async () => {
    const data = { id }
    const config = getConfig();
    setState({ loading: true })
    await axios.post(GET_PARTNER_API_END_POINT, data, config)
      .then((res) => {
        setState({ partner: res.data.partner, loading: false })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  useEffect(() => {
    getPartner();
    getPartnerAccounts();
  }, [id]);

  return (
    <div className="main-container">
      <div className="card-box mb-30">
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <div><h2 className="h4 pd-20">{state?.partner?.name}</h2></div>
            <div className='mb-2'>
              {state?.loading ? <div class="spinner-border text-primary" role="status" /> : null}
            </div>
          </div>
          <div>
            <button className='btn gleamdesk-blue mr-4' onClick={() => setState({ openAddAccountModal: true })} disabled={state?.loading}>
              <i className='fa fa-plus' />
              <span className='ml-2'>Add account</span>
            </button>
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">createdAt</th>
              <th scope="col">Last update</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {(state.accounts || []).map((account) => {
              const { _id, name, user, createdAt, updatedAt } = account;
              return (
                <tr>
                  <th scope="row" className='name'>{name}</th>
                  <td scope="row">{moment(createdAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row">{moment(updatedAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row">
                    <button className='btn btn-secondary btn-sm my-1 mx-1' onClick={() => setState({ openEditAccountModal: true, accountId: _id })} disabled={state?.loading}>
                      <i className='fa fa-pencil' />
                    </button>
                    <button className='btn btn-danger btn-sm my-1 mx-1' onClick={() => deleteAccount(_id)} disabled={state?.loading}>
                      <i className='fa fa-trash' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <AddPartnerAccount
        isOpen={state.openAddAccountModal}
        toggle={() => setState({ openAddAccountModal: false })}
        accounts={state.accounts}
        partner={state?.partner}
        getPartnerAccounts={getPartnerAccounts}
      />

      <EditPartnerAccount
        isOpen={state.openEditAccountModal}
        toggle={() => setState({ openEditAccountModal: false })}
        accounts={state.accounts}
        accountId={state?.accountId}
        partner={state?.partner}
        getPartnerAccounts={getPartnerAccounts}
      />
    </div>
  )
}

export default Partner