import React, { useContext, useState } from 'react'
import authContext from '../context/authContext/authContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import errorHandle from '../utils/errorHandle';

const ADMIN_LOGIN_API_URL = process.env.REACT_APP_API_URL + "/api/admin/login";
const FORGOT_PASSWORD_URL = process.env.REACT_APP_GLEAMDESK_URL + '/forgot';
const REGISTER_USER_URL = process.env.REACT_APP_GLEAMDESK_URL + '/register';

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(authContext);

  const intialState = { email: '', password: '' }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  }

  const login = async () => {
    const { email, password } = state || {}
    const isValid = email && password
    if (!isValid) {
      return;
    }

    const data = { email, password }
    await axios.post(ADMIN_LOGIN_API_URL, data)
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data.user))
        setUser(res.data.user);
        toast.success(res.data.message, { position: 'bottom-right' });
        navigate('/');
        return;
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  return (
    <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-7">
            <img src="assets/images/login-page-img.png" alt="" />
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="login-box bg-white box-shadow border-radius-10">
              <div className="login-title">
                <h2 className="text-center text-primary">Login To GleamDesk Admin</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="select-role">
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className="btn focus active border-primary">
                      <input type="radio" name="options" id="admin" />
                      <div className="icon">
                        <img src="assets/images/briefcase.svg" className="svg" alt="" />
                      </div>
                      <span>I'm</span>
                      Admin
                    </label>
                    <label className="btn disabled">
                      <input type="radio" name="options" id="user" />
                      <div className="icon">
                        <img src="assets/images/person.svg" className="svg" alt="" />
                      </div>
                      <span>I'm</span>
                      User
                    </label>
                  </div>
                </div>
                <div className="input-group custom">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Username"
                    onChange={(e) => setState({ email: e.target.value })}
                  />
                  <div className="input-group-append custom">
                    <span className="input-group-text">
                      <i className="icon-copy dw dw-user1" />
                    </span>
                  </div>
                </div>
                <div className="input-group custom">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="**********"
                    onChange={(e) => setState({ password: e.target.value })}
                  />
                  <div className="input-group-append custom">
                    <span className="input-group-text">
                      <i className="dw dw-padlock1" />
                    </span>
                  </div>
                </div>
                <div className="row pb-30">
                  <div className="col-6">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        Remember
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="forgot-password">
                      <a href={FORGOT_PASSWORD_URL} target="_blank">Forgot Password</a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-group mb-0">
                      {/*
											use code for form submit
											<input class="btn btn-primary btn-lg btn-block" type="submit" value="Sign In">
										*/}
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={handleSubmit}
                      // href="index.html"
                      >
                        Sign In
                      </button>
                    </div>
                    <div
                      className="font-16 weight-600 pt-10 pb-10 text-center"
                      data-color="#707373"
                    >
                      OR
                    </div>
                    <div className="input-group mb-0">
                      <a
                        className="btn btn-outline-primary btn-lg btn-block"
                        href={REGISTER_USER_URL}
                        target="_blank"
                      >
                        Register To Create Account
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </div >

  )
}


export default Login