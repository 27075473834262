import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import authContext from '../context/authContext/authContext';
import errorHandle from '../utils/errorHandle';

const GET_SUBSCRIBERS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getSubscribers";
const DELETE_SUBSCRIBER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deleteSubscriber";

const Subscribers = () => {
  const { getConfig } = useContext(authContext)
  const config = getConfig()

  const intialState = { loading: false, subscribers: [] }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const getSubscribers = async () => {
    setState({ loading: true })

    await axios.get(GET_SUBSCRIBERS_API_END_POINT, config)
      .then((res) => {
        const { subscribers } = res?.data
        setState({ subscribers, loading: false })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }


  const deleteSubscriber = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeleteSubscriber(id);
      }
    });
  }

  const confirmDeleteSubscriber = async (id) => {
    setState({ loading: true })
    const data = { id }
    await axios.post(DELETE_SUBSCRIBER_API_END_POINT, data, config)
      .then((res) => {
        setState({ loading: false })
        toast.success(res?.data?.message, { position: 'bottom-right' });
        getSubscribers();
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  useEffect(() => {
    getSubscribers();
  }, []);

  return (
    <div className='main-container pb-1'>
      <div className="card-box mb-30">
        <div className='d-flex align-items-center'>
          <div><h2 className="h4 pd-20">GleamDesk Subscribers</h2></div>
          <div className='mb-2'>
            {state.loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
        {/* <h2 className="h4 pd-20">GleamDesk Subscribers</h2> */}
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Created </th>
              <th scope="col">Updated</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {(state?.subscribers || []).map((subscriber) => {
              const { _id, email, subscribe, createdAt, updatedAt } = subscriber;
              return (
                <tr key={_id}>
                  <th scope="row">{email}</th>
                  <td scope="row">{moment(createdAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row">{moment(updatedAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row">
                    {subscribe ? (<span className='badge badge-pill gleamdesk-orange'>subscribe</span>) : (<span className='badge badge-danger badge-pill fs-13'>Unsubscribe</span>)}
                  </td>
                  <td scope="row">
                    <button className='btn btn-danger btn-sm my-1 mx-1' onClick={() => deleteSubscriber(_id)} disabled={state.loading}>
                      <i className='fa fa-trash' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Subscribers