import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

import authContext from '../../context/authContext/authContext';
import errorHanle from '../../utils/errorHandle';
import { useDispatch, useSelector } from 'react-redux';
import { addPartnerAction } from '../../redux/actions/partnersAction';

const IMAGE_UPLOAD_ENDPOINT = "https://api.cloudinary.com/v1_1/dnut9ipl0/image/upload"

const AddPartner = ({ isOpen, toggle, getPartners, loading }) => {
  const dispatch = useDispatch();
  const { getConfig } = useContext(authContext);
  const config = getConfig();

  const intialState = { name: '', nameError: '', image: '', imageUrl: '', imageError: '', content: '', addPartnerError: '', note: '' }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const onHideModal = () => {
    setState(intialState)
    toggle();
  }

  const validateName = () => {
    if (!state.name) {
      setState({ nameError: '*Please enter partner name' });
    }
  }

  const validateImage = () => {
    if (!state.image) {
      setState({ imageError: '*Please choose partner image' });
    }
  }


  const handleFileChange = (files) => {
    if (!(files || []).length) {
      setState({ imageError: "Upload an image" })
      return;
    }

    const file = files[0]
    const fileSize = file?.size * 0.000001

    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      setState({ imageError: "choose jpeg or png image" })
      return
    }

    if (fileSize > 1) {
      setState({ imageError: "File size not higer than 1MB!" })
      toast.error("File size not higer than 1MB!");
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setState({ image: file, imageUrl });
  }

  const addPartner = async () => {
    const { name, nameError, image, imageError, content, addPartnerError, note } = state;

    const isValid = name && image && content && (nameError === intialState.nameError) && (imageError === intialState.imageError) && (addPartnerError === intialState.addPartnerError);
    if (!isValid) {
      setState({ addPartnerError: '*Please fill all the required fields' });
      return;
    }

    let fileUrl = ''
    if (image && typeof (image) !== "string") {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', 'gleamdesk');
      formData.append('cloud_name', 'dnut9ipl0');
      try {
        const { data } = await axios.post(IMAGE_UPLOAD_ENDPOINT, formData);
        fileUrl = data?.url
      } catch (err) {
        toast.error(err?.response?.data?.error || err.message)
        return;
      }
    }

    const data = { name, image: fileUrl, content, note }
    dispatch(addPartnerAction(data, config, getPartners, onHideModal))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addPartner();
  }

  const closeBtn = () => <button className='btn btn-close' onClick={onHideModal}>X</button>;

  return (
    <Modal isOpen={isOpen} toggle={onHideModal} size='lg'>
      <ModalHeader toggle={onHideModal} close={closeBtn()}>
        <div className='d-flex align-items-center'>
          <div>Add Partner</div>
          <div className='ml-3'>
            {loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              class="form-control"
              value={state?.name}
              onChange={(e) => setState({ name: e.target.value })}
              onBlur={validateName}
              onFocus={() => setState({ nameError: '', addPartnerError: '' })}
              disabled={loading}
            />
            {state.nameError ? <div className='text-danger ml-2'>{state.nameError}</div> : null}
          </div>
          <div class="form-group">
            <label>Image</label>
            {state.imageUrl ? (
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-center ml-3'>
                  <img src={state.imageUrl} className='img-add-partner' alt="partner image" />
                </div>
                <div>
                  <input
                    type="file"
                    class="form-control"
                    onChange={(e) => handleFileChange(e.target.files)}
                    onBlur={validateImage}
                    onFocus={() => setState({ imageError: '', addPartnerError: '' })}
                    disabled={loading}
                  />
                </div>
              </div>
            ) : (
              <>
                <input
                  type="file"
                  class="form-control"
                  onChange={(e) => handleFileChange(e.target.files)}
                  onBlur={validateImage}
                  onFocus={() => setState({ imageError: '', addPartnerError: '' })}
                  disabled={loading}
                />
                {state?.imageError ? <div className="text-danger ml-2">{state?.imageError}</div> : null}
              </>
            )}
          </div>

          <div class="form-group">
            <label>Note</label>
            <input
              type="text"
              class="form-control"
              value={state.note}
              onChange={(e) => setState({ note: e.target.value })}
              disabled={loading}
            />
            <small className='text-muted ml-2'>This note is only for admin</small>
          </div>

          <div class="form-group">
            <label>content</label>
            <div className='mb-5 partner-content'>
              <Editor
                apiKey='5an9l2os1l8x6d86wh1gb5fyutt4cbse9iz601i9wrp3756t'
                value={state?.content || ''}
                init={{
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Author name',
                  mergetags_list: [
                    { value: 'First.Name', title: 'First Name' },
                    { value: 'Email', title: 'Email' },
                  ],
                }}
                disabled={loading}
                onEditorChange={(content) => setState({ content })}
              />
            </div>
          </div>
          <div class="form-group">
            <label>Preview</label>
            <div dangerouslySetInnerHTML={{ __html: state.content || '' }} />
          </div>
          {state?.addPartnerError ? <div className="text-center text-danger">{state?.addPartnerError}</div> : null}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHideModal} disabled={loading}>
          Cancel
        </Button>
        <Button color="gleamdesk-blue gleamdesk-orange" onClick={handleSubmit} disabled={loading}>
          Add Partner
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddPartner