import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

import authContext from '../../context/authContext/authContext';
import errorHandle from '../../utils/errorHandle';

const EDIT_STOCK_ENDPOINT = process.env.REACT_APP_API_URL + "/api/admin/editStock";
const IMAGE_UPLOAD_ENDPOINT = "https://api.cloudinary.com/v1_1/dnut9ipl0/image/upload"

const EditStock = ({ isOpen, toggle, stockId, stock, getStock }) => {
  const { getConfig } = useContext(authContext)
  const intialState = { loading: false, nameError: '', imageError: '', editStockError: '', stock: {} }

  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const onHideModal = () => {
    setState({ ...intialState });
    toggle();
  }

  useEffect(() => {
    if (stock) {
      stock.imageUrl = stock.image;
    }

    setState({ stock: JSON.parse(JSON.stringify(stock || {})) })
  }, [isOpen, stockId]);


  const handleSubmit = (e) => {
    e.preventDefault();
    editStock();
  }

  const editStock = async () => {
    setState({ loading: true })
    const { stock: { name, image, content, imageUrl }, nameError, imageError, editStockError } = state;
    const isValid = name && image && content && (nameError === intialState.nameError) && (imageError === intialState.imageError) && (editStockError === intialState.editStockError);
    if (!isValid) {
      setState({ editStockError: '*Please fill all the required fields', loading: false });
      return;
    }

    let fileUrl = imageUrl;
    if (image && typeof (image) !== "string") {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', 'gleamdesk');
      formData.append('cloud_name', 'dnut9ipl0');
      try {
        const { data } = await axios.post(IMAGE_UPLOAD_ENDPOINT, formData);
        fileUrl = data?.url
      } catch (err) {
        toast.error(err?.response?.data?.error || err.message)
        return;
      }
    }

    const config = getConfig()
    const update = { ...state.stock, image: fileUrl }
    await axios.post(EDIT_STOCK_ENDPOINT, { update }, config)
      .then((res) => {
        toast.success(res?.data?.message, { position: 'bottom-right' });
        getStock();
        onHideModal();
        setState({ loading: false })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const validateName = () => {
    if (!state.stock.name) {
      setState({ nameError: '*Please enter stock name' });
    }
  }

  const validateImage = () => {
    if (!state.stock.image) {
      setState({ imageError: '*Please choose stock image' });
    }
  }

  const handleFileChange = (files) => {
    if (!(files || []).length) {
      setState({ imageError: "Upload an image" })
      return;
    }

    const file = files[0]
    const fileSize = file?.size * 0.000001

    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      setState({ imageError: "choose jpeg or png image" })
      return
    }

    if (fileSize > 1) {
      setState({ imageError: "File size not higer than 1MB!" })
      toast.error("File size not higer than 1MB!");
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    updateStockstate('image', file);
    updateStockstate('imageUrl', imageUrl);
  }

  const updateStockstate = (key, value) => {
    const { stock } = state || {};
    stock[key] = value;
    setState({ stock })
  }

  const closeBtn = () => <button className='btn btn-close' onClick={onHideModal}>X</button>;

  return (
    <Modal isOpen={isOpen} toggle={onHideModal} size='lg'>
      <ModalHeader toggle={onHideModal} close={closeBtn()}>
        <div className='d-flex align-items-center'>
          <div>Edit Stock</div>
          <div className='ml-3'>
            {state.loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={state?.stock?.name}
              onChange={(e) => updateStockstate('name', e.target.value)}
              onBlur={validateName}
              onFocus={() => setState({ nameError: '', editStockError: '' })}
              disabled={state.loading}
            />
            {state?.nameError ? <div className='text-danger ml-2'>{state.nameError}</div> : null}
          </div>

          <div className="form-group">
            <label>Image</label>
            {state?.stock?.imageUrl ? (
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-center ml-3'>
                  <img src={state?.stock?.imageUrl} className='img-add-partner' alt="partner image" />
                </div>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleFileChange(e.target.files)}
                    onBlur={validateImage}
                    onFocus={() => setState({ imageError: '', editStockError: '' })}
                    disabled={state.loading}
                  />
                </div>
              </div>
            ) : (
              <>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => handleFileChange(e.target.files)}
                  onBlur={validateImage}
                  onFocus={() => setState({ imageError: '', editStockError: '' })}
                  disabled={state.loading}
                />
                {state?.imageError ? <div className="text-danger ml-2">{state?.imageError}</div> : null}
              </>
            )}
          </div>

          <div className="form-group">
            <label>content</label>
            <div className='mb-5 partner-content'>
              <Editor
                apiKey='5an9l2os1l8x6d86wh1gb5fyutt4cbse9iz601i9wrp3756t'
                value={state?.stock?.content || ''}
                init={{
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Author name',
                  mergetags_list: [
                    { value: 'First.Name', title: 'First Name' },
                    { value: 'Email', title: 'Email' },
                  ],
                  ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                }}
                disabled={state.loading}
                onEditorChange={(content) => updateStockstate('content', content)}
              // initialValue="Welcome Admin"
              />
            </div>
          </div>
          <div className="form-group">
            <label>Preview</label>
            <div dangerouslySetInnerHTML={{ __html: state?.stock?.content || '' }} />
          </div>
          {state?.editStockError ? <div className="text-center text-danger">{state?.editStockError}</div> : null}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHideModal} disabled={state.loading}>
          Cancel
        </Button>
        <Button type='submit' color="gleamdesk-blue gleamdesk-orange" onClick={handleSubmit} disabled={state.loading}>
          Update Stock
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditStock;