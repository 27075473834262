import axios from "axios";
import constants from "../constants";
const { GET_APPOITMENTS, GET_APPOITMENTS_SUCCESS, GET_APPOITMENTS_ERROR, DELETE_APPOITMENT, DELETE_APPOITMENT_SUCCESS, DELETE_APPOITMENT_ERROR } = constants;

const GET_APPOINTMENTS_API_URL = process.env.REACT_APP_API_URL + "/api/admin/getAllAppointments";
const DELETE_APPOINTMENT_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deleteAppointment";

export const getAppointmentsAction = (config) => async (dispatch) => {
  dispatch({ type: GET_APPOITMENTS })
  await axios.get(GET_APPOINTMENTS_API_URL, config)
    .then((res) => dispatch({ type: GET_APPOITMENTS_SUCCESS, appointments: res?.data?.appointments }))
    .catch((error) => dispatch({ type: GET_APPOITMENTS_ERROR, error }))
}

export const deleteAppointmentAction = (data, config, callback) => async (dispatch) => {
  dispatch({ type: DELETE_APPOITMENT })
  await axios.post(DELETE_APPOINTMENT_API_END_POINT, data, config)
    .then((res) => {
      dispatch({ type: DELETE_APPOITMENT_SUCCESS, message: res?.data?.message })
      callback();
    })
    .catch((error) => dispatch({ type: DELETE_APPOITMENT_ERROR, error }))
}