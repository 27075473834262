import React, { useContext, useEffect } from 'react'
import Swal from 'sweetalert2';
import moment from 'moment';

import authContext from '../context/authContext/authContext';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAppointmentAction, getAppointmentsAction } from '../redux/actions/appointmentAction';

const Appointments = () => {
  const dispatch = useDispatch();
  const { getConfig } = useContext(authContext)

  const { loading, completedAppointments, upComingAppointments } = useSelector((state) => state.appointments);

  const getAppointments = async () => {
    const config = getConfig()
    dispatch(getAppointmentsAction(config))
  }

  useEffect(() => {
    getAppointments();
  }, []);

  const deleteAppointment = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeleteAppointment(id);
      }
    });
  }

  const confirmDeleteAppointment = async (id) => {
    const data = { id }
    const config = getConfig();
    dispatch(deleteAppointmentAction(data, config, getAppointments))
  }

  return (
    <div className="main-container">
      <div className="card-box mb-30">
        <div className='d-flex align-items-center'>
          <div><h2 className="h4 pd-20">GleamDesk Appointments</h2></div>
          <div className='mb-2'>
            {loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">User</th>
              <th scope="col">Title</th>
              <th scope="col">Phone</th>
              <th scope="col">Note</th>
              <th scope="col">Time</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {(upComingAppointments || []).map((appointment) => {
              const { user, title, phone, note, dateTime, _id } = appointment;
              return (
                <tr key={_id}>
                  <th scope="row" className='name pl-3'>{user}</th>
                  <td scope="row">{title}</td>
                  <td scope="row">{phone || '-'}</td>
                  <td scope="row"><span>{note || '-'}</span></td>
                  <td scope="row">{moment(dateTime).format("dddd, MMMM Do YYYY, h:mm a")}</td>

                  <td scope="row">
                    <div className='badge gleamdesk-orange badge-pill p-2'>Upcoming</div>
                  </td>
                  <td scope="row">
                    <button className='btn btn-danger btn-sm my-1 mx-1' onClick={() => deleteAppointment(_id)}>
                      <i className='fa fa-trash' />
                    </button>
                  </td>
                </tr>
              )
            })}

            {(completedAppointments || []).map((appointment) => {
              const { user, title, phone, note, dateTime, _id } = appointment;
              return (
                <tr key={_id}>
                  <th scope="row" className='name pl-3'>{user}</th>
                  <td scope="row">{title}</td>
                  <td scope="row">{phone || '-'}</td>
                  <td scope="row">{note || '-'}</td>
                  <td scope="row">{moment(dateTime).format("dddd, MMMM Do YYYY, h:mm a")}</td>

                  <td scope="row">
                    <div className='badge badge-success badge-pill p-2"'>Completed</div>
                  </td>
                  <td scope="row">
                    <button className='btn btn-danger btn-sm my-1 mx-1' disabled onClick={() => deleteAppointment(_id)}>
                      <i className='fa fa-trash' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Appointments