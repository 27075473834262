import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import authContext from '../context/authContext/authContext';
import AddPropdesk from '../components/modals/AddPropdesk';
import AddStock from '../components/modals/AddStock';
import EditStock from '../components/modals/EditStock';
import errorHandle from '../utils/errorHandle';

const GET_STOCK_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getStock";
const DELETE_STOCK_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deleteStock";

const Stock = () => {
  const { getConfig } = useContext(authContext);
  const config = getConfig();

  const intialState = { loading: false, stock: null }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const getStock = async () => {
    setState({ loading: true })
    await axios.get(GET_STOCK_API_END_POINT, config)
      .then((res) => setState({ stock: res?.data?.stock, loading: false }))
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const deleteStock = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeleteStock(state?.stock?._id);
      }
    });
  }

  const confirmDeleteStock = async (id) => {
    setState({ loading: true })
    const data = { id }
    await axios.post(DELETE_STOCK_API_END_POINT, data, config)
      .then((res) => {
        setState({ loading: false })
        toast.success(res?.data?.message, { position: 'bottom-right' });
        getStock();
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  useEffect(() => {
    getStock();
  }, []);

  return (
    <div className='main-container pb-1'>
      <div className="card-box mb-30">
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <div><h2 className="h4 pd-20">Stock Of the Month</h2></div>
            <div className='mb-2'>
              {state.loading ? <div class="spinner-border text-primary" role="status" /> : null}
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-center'>
            {!state?.stock ? (
              <button className='btn gleamdesk-blue mr-4' onClick={() => setState({ openAddStockModal: true })} disabled={state.loading}>
                <i className='fa fa-plus' />
                <span className='ml-2'>Add Stock</span>
              </button>
            ) : null}

            {state?.stock ? (
              <button className='btn gleamdesk-orange mr-4' onClick={() => setState({ openEditStockModal: true })} disabled={state.loading}>
                <i className='fa fa-pencil' />
                <span className='ml-2'>Edit Stock</span>
              </button>
            ) : null}

            {state?.stock ? (
              <button className='btn btn-danger mr-4' onClick={deleteStock} disabled={state.loading}>
                <i className='fa fa-trash' />
                <span className='ml-2'>Delete Stock</span>
              </button>
            ) : null}
          </div>
        </div>

      </div>
      {state?.stock ? (
        <div className='container'>
          <div className='text-center fs-30 mb-5'><strong>{state?.stock?.name}</strong></div>
          <div className='row'>
            <div className='col-md-6 text-center'>
              <img src={state?.stock?.image} className='img-stock' />
            </div>

            <div className='col-md-6'>
              <div dangerouslySetInnerHTML={{ __html: state?.stock?.content || '' }} />
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center'>
          No stock of the month available
        </div>
      )}

      <AddStock
        isOpen={state?.openAddStockModal}
        toggle={() => setState({ openAddStockModal: false })}
        getStock={getStock}
      />

      <EditStock
        isOpen={state?.openEditStockModal}
        toggle={() => setState({ openEditStockModal: false })}
        stockId={state?.stock?._id}
        stock={state?.stock}
        getStock={getStock}
      />
    </div>
  )
}

export default Stock;