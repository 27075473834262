import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import authContext from '../context/authContext/authContext';
import AddPropdesk from '../components/modals/AddPropdesk';
import EditPropdesk from '../components/modals/EditPropdesk';
import errorHandle from '../utils/errorHandle';

const GET_PROPDESKS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getPropdesks";
const DELETE_PROPDESKS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/deletePropdesk";

const getYearsArray = () => {
  const years = [];
  for (let i = START_YEAR; i <= END_YEAR; i) {
    years.push(i);
    i += 1
  }
  return years;
}

const START_YEAR = 2018;
const END_YEAR = new Date().getFullYear();
const YEARS_ARRAY = getYearsArray();

const Propdesks = () => {
  const { getConfig } = useContext(authContext);
  const config = getConfig();

  const intialState = { loading: false, propdesks: [] }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const getPropdesks = async () => {
    setState({ loading: true })
    await axios.get(GET_PROPDESKS_API_END_POINT, config)
      .then((res) => setState({ propdesks: res?.data?.propdesks, loading: false }))
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const deletePropdesk = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeletePropdesk(id);
      }
    });
  }

  const confirmDeletePropdesk = async (id) => {
    setState({ loading: true })
    const data = { id }
    await axios.post(DELETE_PROPDESKS_API_END_POINT, data, config)
      .then((res) => {
        setState({ loading: false })
        toast.success(res?.data?.message, { position: 'bottom-right' });
        getPropdesks();
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  useEffect(() => {
    getPropdesks();
  }, []);

  return (
    <div className='main-container pb-1'>
      <div className="card-box mb-30">
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <div><h2 className="h4 pd-20">GleamDesk Partners</h2></div>
            <div className='mb-2'>
              {state.loading ? <div class="spinner-border text-primary" role="status" /> : null}
            </div>
          </div>
          <div>
            <button className='btn gleamdesk-blue mr-4' onClick={() => setState({ openAddPropdeskModal: true })} disabled={state.loading}>
              <i className='fa fa-plus' />
              <span className='ml-2'>Add Propdesk</span>
            </button>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Propdesk</th>
              <th scope="col">Percentage </th>
              <th scope="col">Status</th>
              <th scope="col">createdAt</th>
              <th scope="col">Last update</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {(state?.propdesks || []).map((propdesk) => {
              const { _id, month, year, percentage, status, createdAt, updatedAt } = propdesk;
              return (
                <tr key={_id}>
                  <th scope="row" className='pl-3'>{month} - {year}</th>
                  <td scope="row" className='pl-4'>{percentage}%</td>
                  <td scope="row">
                    {status === 'Up' ? (<span className='badge badge-pill gleamdesk-orange'>{status}</span>) : (<span className='badge badge-danger badge-pill fs-13'>{status}</span>)}
                  </td>
                  <td scope="row">{moment(createdAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row">{moment(updatedAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row" className='pl-0'>
                    <button className='btn btn-secondary btn-sm my-1 mx-1' onClick={() => setState({ openEditPropdeskModal: true, propdeskId: _id })} disabled={state.loading}>
                      <i className='fa fa-pencil' />
                    </button>
                    <button className='btn btn-danger btn-sm my-1 mx-1' onClick={() => deletePropdesk(_id)} disabled={state.loading}>
                      <i className='fa fa-trash' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <AddPropdesk
        key={() => new Date()}
        isOpen={state?.openAddPropdeskModal}
        toggle={() => setState({ openAddPropdeskModal: false })}
        getPropdesks={getPropdesks}
        YEARS_ARRAY={YEARS_ARRAY}
        END_YEAR={END_YEAR}
      />

      <EditPropdesk
        key={() => new Date()}
        isOpen={state?.openEditPropdeskModal}
        toggle={() => setState({ openEditPropdeskModal: false, propdeskId: null })}
        propdesks={state.propdesks}
        getPropdesks={getPropdesks}
        YEARS_ARRAY={YEARS_ARRAY}
        propdeskId={state?.propdeskId}
      />
    </div>
  )
}

export default Propdesks