import constants from "../constants";
import successHandle from "../../utils/successHandle";
import errorHandle from "../../utils/errorHandle";

const { GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR, EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_ERROR, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR } = constants;
const initialState = {}

export const UsersReducer = (state = initialState, action) => {
  const { type, users, error, message } = action;
  switch (type) {
    case GET_USERS:
    case EDIT_USER:
    case DELETE_USER:
      return { ...state, loading: true, error: '', message: '' }

    case GET_USERS_ERROR:
    case EDIT_USER_ERROR:
    case DELETE_USER_ERROR:
      return { ...state, loading: false, error: errorHandle(error) }

    case GET_USERS_SUCCESS:
      return { ...state, loading: false, users }
    case EDIT_USER_SUCCESS:
      return { ...state, loading: false, message: successHandle(message) }
    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, message: successHandle(message) }
    default:
      return state
  }
}
