export default {

  // Login
  ADMIN_LOGIN: 'ADMIN_LOGIN',
  ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  ADMIN_LOGIN_ERROR: 'ADMIN_LOGIN_ERROR',

  // Uers
  GET_USERS: 'GET_USERS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  EDIT_USER: 'EDIT_USERS',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_ERROR: 'EDIT_USER_ERROR',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',

  // Appoinments
  GET_APPOITMENTS: 'GET_APPOITMENTS',
  GET_APPOITMENTS_SUCCESS: 'GET_APPOITMENTS_SUCCESS',
  GET_APPOITMENTS_ERROR: 'GET_APPOITMENTS_ERROR',
  DELETE_APPOITMENT: 'DELETE_APPOITMENT',
  DELETE_APPOITMENT_SUCCESS: 'DELETE_APPOITMENT_SUCCESS',
  DELETE_APPOITMENT_ERROR: 'DELETE_APPOITMENT_ERROR',

  // Partners
  GET_PARTNERS: 'GET_PARTNERS',
  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  GET_PARTNERS_ERROR: 'GET_PARTNERS_ERROR',
  ADD_PARTNER: 'ADD_PARTNER',
  ADD_PARTNER_SUCCESS: 'ADD_PARTNER_SUCCESS',
  ADD_PARTNER_ERROR: 'ADD_PARTNER_ERROR',
  EDIT_PARTNER: 'EDIT_PARTNER',
  EDIT_PARTNER_SUCCESS: 'EDIT_PARTNER_SUCCESS',
  EDIT_PARTNER_ERROR: 'EDIT_PARTNER_ERROR',
  DELETE_PARTNER: 'DELETE_PARTNER',
  DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
  DELETE_PARTNER_ERROR: 'DELETE_PARTNER_ERROR',

  // Partner accounts
  GET_PARTNER_ACCCOUNT: 'GET_PARTNER_ACCCOUNT',
  GET_PARTNER_ACCCOU_SUCCESS: 'GET_PARTNER_ACCCOU_SUCCESS',
  GET_PARTNER_ACCCOUNT_ERROR: 'GET_PARTNER_ACCCOUNT_ERROR',
  ADD_PARTNER_ACCOUNT: 'ADD_PARTNER_ACCOUNT',
  ADD_PARTNER_ACCOUNT_SUCCESS: 'ADD_PARTNER_ACCOUNT_SUCCESS',
  ADD_PARTNER_ACCOUNT_ERROR: 'ADD_PARTNER_ACCOUNT_ERROR',
  EDIT_PARTNER_ACCOUNT: 'EDIT_PARTNER_ACCOUNT',
  EDIT_PARTNER_ACCOUNT_SUCCESS: 'EDIT_PARTNER_ACCOUNT_SUCCESS',
  EDIT_PARTNER_ACCOUNT_ERROR: 'EDIT_PARTNER_ACCOUNT_ERROR',
  DELETE_PARTNER_ACCOUNT: 'DELETE_PARTNER_ACCOUNT',
  DELETE_PARTNER_ACCOUNT_SUCCESS: 'DELETE_PARTNER_ACCOUNT_SUCCESS',
  DELETE_PARTNER_ACCOUNT_ERROR: 'DELETE_PARTNER_ACCOUNT_ERROR',

  //   // // subscribers
  //   // GET_SUBSCRIBERS,
  //   // GET_SUBSCRIBERS_SUCCESS,
  //   // GET_SUBSCRIBERS_ERROR,
  //   // DELETE_SUBSCRIBERS,
  //   // DELETE_SUBSCRIBERS_SUCCESS,
  //   // DELETE_SUBSCRIBERS_ERROR,

  //   // // propdesks
  //   // GET_PROPDESKS,
  //   // GET_PROPDESKS_SUCCESS,
  //   // GET_PROPDESKS_ERROR,
  //   // ADD_PROPDESKS,
  //   // ADD_PROPDESKS_SUCCESS,
  //   // ADD_PROPDESKS_ERROR,
  //   // EDIT_PROPDESKS,
  //   // EDIT_PROPDESKS_SUCCESS,
  //   // EDIT_PROPDESKS_ERROR,
  //   // DELETE_PROPDESKS,
  //   // DELETE_PROPDESKS_SUCCESS,
  //   // DELETE_PROPDESKS_ERROR,

  //   // stock of the month
  //   // GET_STOCK,
  //   // GET_STOCK_SUCCESS,
  //   // GET_STOCK_ERROR,
  //   // ADD_STOCK,
  //   // ADD_STOCK_SUCCESS,
  //   // ADD_STOCK_ERROR,
  //   // EDIT_STOCK,
  //   // EDIT_STOCK_SUCCESS,
  //   // EDIT_STOCK_ERROR,
  //   // DELETE_STOCK,
  //   // DELETE_STOCK_SUCCESS,
  //   // DELETE_STOCK_ERROR

}