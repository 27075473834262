import constants from "../constants";
import successHandle from "../../utils/successHandle";
import errorHandle from "../../utils/errorHandle";

const {
  GET_PARTNERS, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR,
  ADD_PARTNER, ADD_PARTNER_SUCCESS, ADD_PARTNER_ERROR,
  EDIT_PARTNER, EDIT_PARTNER_SUCCESS, EDIT_PARTNER_ERROR,
  DELETE_PARTNER, DELETE_PARTNER_SUCCESS, DELETE_PARTNER_ERROR
} = constants;

const initialState = { loading: false, partners: [], message: '', error: '' }

export const partnerReducer = (state = initialState, action) => {
  const { type, partners, message, error } = action;
  switch (type) {
    case GET_PARTNERS:
    case ADD_PARTNER:
    case EDIT_PARTNER:
    case DELETE_PARTNER:
      return { ...state, loading: true, error: '', message: '' }

    case GET_PARTNERS_SUCCESS:
      return { ...state, loading: false, partners }
    case ADD_PARTNER_SUCCESS:
    case EDIT_PARTNER_SUCCESS:
    case DELETE_PARTNER_SUCCESS:
      return { ...state, loading: false, message: successHandle(message) }

    case GET_PARTNERS_ERROR:
    case ADD_PARTNER_ERROR:
    case EDIT_PARTNER_ERROR:
    case DELETE_PARTNER_ERROR:
      return { ...state, loading: false, error: errorHandle(error) }

    default:
      return state
  }
}