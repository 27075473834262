import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import authContext from '../context/authContext/authContext'
import UsersChart from '../components/charts/UsersChart';
import AppointmentsChart from '../components/charts/AppointmentsChart';
import PropdesksChart from '../components/charts/PropdesksChart';
import AccountsChart from '../components/charts/PartnersAccountsChart';
import axios from 'axios';
import { toast } from 'react-toastify';
import PartnersAccountsChart from '../components/charts/PartnersAccountsChart';
import errorHandle from '../utils/errorHandle';

const GET_DASHBOARD_DATA_API_URL = process.env.REACT_APP_API_URL + "/api/admin/getDashboardData";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, getConfig } = useContext(authContext)

  const intialState = { loading: false, chartData: {}, counts: {} }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    } else {
      getDashboardData()
    }
  }, []);


  const getDashboardData = async () => {
    setState({ loading: true })
    const config = getConfig();
    await axios.get(GET_DASHBOARD_DATA_API_URL, config)
      .then((res) => {
        setState({ loading: false })
        const { chartData, counts } = res?.data
        setState({ chartData, counts })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  return (
    <>
      <div class="mobile-menu-overlay"></div>
      <div className="main-container">
        <div className="pd-ltr-20">
          {state.loading ? (
            <div className='text-center mb-4'>
              <div class="spinner-border text-primary" role="status" />
            </div>
          ) : null}

          <div className="card-box pd-20 height-100-p mb-30">
            <div className="row align-items-center">
              <div className="col-md-4">
                <img src="assets/images/banner-img.png" alt="" />
              </div>
              <div className="col-md-8">
                <h4 className="font-20 weight-500 mb-10 text-capitalize">
                  Welcome back{" "}
                  <div className="weight-600 font-30 text-blue">{user?.name}!</div>
                </h4>
                <p className="font-18 max-width-600">
                  Hello Admin, Great to see you. Here you have access to handle all the users, partners, Subscribers, and user appointments.
                  you can manage all the stuffs of the GleamDesk. and you have wide opportunity to manipulate the data.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 mb-30">
              <div className="card-box height-100-p widget-style1">
                <div className="d-flex flex-wrap align-items-center ml-4">
                  <div><i className='fa fa-users font-32 text text-primary-icon' /></div>
                  <div className="widget-data">
                    <div className="h4 mb-0">{state.counts.users}</div>
                    <div className="weight-600 font-14">Users</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-30">
              <div className="card-box height-100-p widget-style1">
                <div className="d-flex flex-wrap align-items-center  ml-4">
                  <div>
                    <i className='micon dw dw-calendar1 font-32 text-primary-icon' />
                  </div>
                  <div className="widget-data">
                    <div className="h4 mb-0">{state.counts.appointments}</div>
                    <div className="weight-600 font-14">Appointments</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-30">
              <div className="card-box height-100-p widget-style1">
                <div className="d-flex flex-wrap align-items-center  ml-4">
                  <div>
                    <i className='micon fa fa-handshake-o font-32 text-primary-icon' />
                  </div>
                  <div className="widget-data">
                    <div className="h4 mb-0">{state.counts.partners}</div>
                    <div className="weight-600 font-14">Partners</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-30">
              <div className="card-box height-100-p widget-style1">
                <div className="d-flex flex-wrap align-items-center  ml-4">
                  <div>
                    <i className='micon dw dw-user-11 font-32 text-primary-icon' />
                  </div>
                  <div className="widget-data">
                    <div className="h4 mb-0">{state.counts.partnerAcounts}</div>
                    <div className="weight-600 font-14">Partner Accounts</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-4'>
            <div className='col-md-6 my-3'>
              <div className="card-box widget-style1">
                <div>
                  <UsersChart data={state?.chartData?.users} />
                </div>
                <div className="text-center weight-600 font-23">
                  Users
                </div>
              </div>
            </div>
            <div className='col-md-6 my-3'>
              <div className="card-box widget-style1">
                <div>
                  <AppointmentsChart data={state?.chartData?.appointments} />
                </div>
                <div className="text-center weight-600 font-23">
                  Appointments
                </div>
              </div>
            </div>
            <div className='col-md-6 my-3'>
              <div className="card-box widget-style1">
                <div>
                  <PropdesksChart data={state?.chartData?.propdesks} />
                </div>
                <div className="text-center weight-600 font-23">
                  Propdesks
                </div>
              </div>
            </div>
            <div className='col-md-6 my-3'>
              <div className="card-box widget-style1">
                <div>
                  <PartnersAccountsChart data={state?.chartData?.partnerAccounts} />
                </div>
                <div className="text-center weight-600 font-23">
                  Parter Accounts
                </div>
              </div>
            </div>
          </div>

          <div className="footer-wrap pd-20 mb-20 card-box">
            Developed By - &nbsp;
            <a href="https://mappingninja.solutions" target="_blank">
              MappingNinja
            </a>
          </div>
        </div>
      </div>
    </>

  )
}

export default Dashboard;