import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import authContext from '../context/authContext/authContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import dataContext from '../context/dataContext/dataContext';
import errorHanle from '../utils/errorHandle';
import { useDispatch, useSelector } from 'react-redux';
import { getPartnersAction } from '../redux/actions/partnersAction';

const GET_PARTNERS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getPartners";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user, getConfig } = useContext(authContext);
  const { getAllowedPages } = useContext(dataContext)

  const intialState = { partners: [] }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const { partners } = useSelector((state) => state.partners)

  const pages = getAllowedPages(pathname);
  const getPartners = async () => {
    const config = getConfig();
    dispatch(getPartnersAction(config))
  }

  useEffect(() => {
    if (user) {
      getPartners();
    } else {
      navigate('/login');
      return;
    }
  }, [])

  if (!pages.includes(pathname)) {
    return;
  }

  return (
    <div className="left-side-bar">
      <div className="brand-logo">
        <Link to="/dashboard">
          <img src="assets/images/gleamDesk.png" alt="" style={{ height: '90%' }} className="light-logo" />
          <img src="assets/images/gleamDesk.png" alt="" style={{ height: '90%' }} className="dark-logo" />
          <h3 style={{ color: '#0097b2' }}>GleamDesk</h3>
        </Link>
        <div className="close-sidebar" data-toggle="left-sidebar-close">
          <i className="ion-close-round" />
        </div>
      </div>
      <div className="menu-block customscroll">
        <div className="sidebar-menu">
          <ul id="accordion-menu">
            <li>
              <Link className="dropdown-toggle no-arrow" to="/dashboard">
                <span className="micon dw dw-house-1" />
                <span className="mtext">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-toggle no-arrow" to="/users">
                <span className="micon dw dw-user-11" />
                <span className="mtext">Users</span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-toggle no-arrow" to="/appointments">
                <span className="micon dw dw-calendar1" />
                <span className="mtext">Appointments</span>
              </Link>
            </li>
            {/* <li>
              <Link className="dropdown-toggle no-arrow" to="/partners">
                <span className="micon fa fa-handshake-o" />
                <span className="mtext">Partners</span>
              </Link>
            </li> */}
            <li className="dropdown">
              <Link to="/partners" className="dropdown-toggle">
                <span className="micon fa fa-handshake-o" />
                <span className="mtext">Partners</span>
              </Link>
              <ul className="submenu">
                {(partners || []).map((partner) => {
                  return (
                    <li>
                      <Link to={`/partner/${partner?._id}`}>{partner?.name}</Link>
                    </li>
                  )
                })}
                {/* <li>
                  <a href="video-player.html">Video Player</a>
                </li>
                <li>
                  <a href="login.html">Login</a>
                </li>
                <li>
                  <a href="forgot-password.html">Forgot Password</a>
                </li>
                <li>
                  <a href="reset-password.html">Reset Password</a>
                </li> */}
              </ul>
            </li>
            <li>
              <Link className="dropdown-toggle no-arrow" to="/subscribers">
                <span className="micon fa fa-envelope-o" />
                <span className="mtext">Subscribers</span>
              </Link>
            </li>
            <li>
              <Link to="/propdesks" className="dropdown-toggle no-arrow">
                <span className="micon fa fa-line-chart" />
                <span className="mtext">Propdesks</span>
              </Link>
            </li>
            <li>
              <Link to="/stock" className="dropdown-toggle no-arrow">
                <span className="micon fa fa-pie-chart" />
                <span className="mtext">Stock of the month</span>
              </Link>
            </li>
            {/* <li>
              <a href="sitemap.html" className="dropdown-toggle no-arrow">
                <span className="micon dw dw-diagram" />
                <span className="mtext">Sitemap</span>
              </a>
            </li> */}

            <li>
              <a
                href={process.env.REACT_APP_GLEAMDESK_URL}
                target="_blank"
                className="dropdown-toggle no-arrow"
              >
                <span className="micon dw dw-paper-plane1" />
                <span className="mtext">
                  GleamDesk
                  <img src="/assets/images/gleamDesk.png" alt="" width={25} className='ml-2' />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  )
}

export default Navbar