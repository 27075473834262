import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import authContext from '../context/authContext/authContext';
import AddPartner from '../components/modals/AddPartner';
import EditPartner from '../components/modals/EditPartner';
import { useDispatch, useSelector } from 'react-redux';
import { deletePartnerAction, getPartnersAction } from '../redux/actions/partnersAction';

const Partners = () => {
  const dispatch = useDispatch();
  const { getConfig } = useContext(authContext)
  const config = getConfig();

  const intialState = { partners: [] }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const { loading, partners } = useSelector((state) => state.partners)
  const deletePartner = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeletePartner(id);
      }
    });
  }

  const confirmDeletePartner = async (id) => {
    const data = { id }
    dispatch(deletePartnerAction(data, config, getPartners))
  }

  const getPartners = async () => {
    dispatch(getPartnersAction(config))
  }

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <div className='main-container pb-1'>
      <div className="card-box mb-30">
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <div><h2 className="h4 pd-20">GleamDesk Partners</h2></div>
            <div className='mb-2'>
              {loading ? <div class="spinner-border text-primary" role="status" /> : null}
            </div>
          </div>
          <div>
            <button className='btn gleamdesk-blue mr-4' onClick={() => setState({ openAddPartnerModal: true })} disabled={loading}>
              <i className='fa fa-plus' />
              <span className='ml-2'>Add Partners</span>
            </button>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Note</th>
              <th scope="col">Time</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {(partners || []).map((partner) => {
              const { _id, image, name, note, createdAt, } = partner;
              return (
                <tr key={_id}>
                  <td scope="row"><img src={image} className='img-fluid img-partner' alt="user image" /></td>
                  <td scope="row">{name}</td>
                  <td scope="row">{note || '-'}</td>
                  <td scope="row">{moment(createdAt).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                  <td scope="row">
                    <button className='btn btn-secondary btn-sm my-1 mx-1' onClick={() => setState({ openEditPartnerModal: true, partnerId: _id })} disabled={loading}>
                      <i className='fa fa-pencil' />
                    </button>
                    <button className='btn btn-danger btn-sm my-1 mx-1' onClick={() => deletePartner(_id)} disabled={loading}>
                      <i className='fa fa-trash' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <AddPartner
        key={() => new Date()}
        isOpen={state?.openAddPartnerModal}
        toggle={() => setState({ openAddPartnerModal: false })}
        getPartners={getPartners}
        loading={loading}
      />

      <EditPartner
        key={() => new Date()}
        isOpen={state.openEditPartnerModal}
        toggle={() => setState({ openEditPartnerModal: false, partnerId: null })}
        partnerId={state.partnerId}
        partners={partners || []}
        getPartners={getPartners}
        loading={loading}
      />
    </div >
  )
}

export default Partners