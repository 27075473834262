import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import { toast } from 'react-toastify';

import authContext from '../../context/authContext/authContext';
import dataContext from '../../context/dataContext/dataContext';
import errorHandle from '../../utils/errorHandle';

const EDIT_PROPDESK_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/editPropdesk";

const EditPropdesk = ({ isOpen, toggle, getPropdesks, YEARS_ARRAY, propdeskId, propdesks }) => {
  const { getConfig } = useContext(authContext);
  const { MONTHS_LIST } = useContext(dataContext)

  const intialState = { loading: false, propdesk: {}, percentageError: '', editPropdeskError: '' }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const onHideModal = () => {
    setState(intialState)
    toggle();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    EditPropdesk();
  }

  useEffect(() => {
    const propdesk = (propdesks || []).find((propdesk) => propdesk?._id === propdeskId);
    setState({ propdesk: JSON.parse(JSON.stringify(propdesk || {})) })
  }, [propdeskId]);


  const validatePercentage = () => {
    if (!state?.propdesk?.percentage) {
      setState({ percentageError: '*Please enter percentage' });
    } else if (state?.propdesk?.percentage < 0) {
      setState({ percentageError: '*Please enter valid percentage' });
    }
  }

  const EditPropdesk = async () => {
    const { propdesk: { month, year, percentage, status }, percentageError, editPropdeskError } = state
    const isValid = month && year && percentage && status && (percentageError === intialState.percentageError) && (editPropdeskError === intialState.editPropdeskError);
    if (!isValid) {
      setState({ editPropdeskError: '*Please fill with valid data!' });
      return;
    }

    setState({ loading: true })
    const update = { ...state.propdesk }
    const config = getConfig();
    await axios.post(EDIT_PROPDESK_API_END_POINT, { update }, config)
      .then((res) => {
        setState({ loading: false })
        toast.success(res?.data?.message, { position: 'bottom-right' });
        onHideModal();
        getPropdesks();
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const updatePropdeskState = (key, value) => {
    const { propdesk } = state || {};
    propdesk[key] = value;
    setState({ propdesk })
  }

  const closeBtn = () => <button className='btn btn-close' onClick={onHideModal}>X</button>;

  return (
    <Modal isOpen={isOpen} toggle={onHideModal}>
      <ModalHeader toggle={onHideModal} close={closeBtn()}>
        <div className='d-flex align-items-center'>
          <div>Edit Propdesk</div>
          <div className='ml-3'>
            {state.loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div class="form-group1">
                <label>Month</label>
                <select disabled class="form-select custom-select" aria-label="Default select example" onChange={(e) => setState({ month: e.target.value })}>
                  {MONTHS_LIST.map((month) => {
                    return (
                      <option value={month} selected={month === (state?.propdesk?.month)}>{month}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div class="form-group">
                <label>Year</label>
                <select disabled class="form-select custom-select" aria-label="Default select example" onChange={(e) => setState({ year: e.target.value })}>
                  {(YEARS_ARRAY || []).map((year) => {
                    return (
                      <option value={year} selected={year === (Number(state?.propdesk?.year))}>{year}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div class="form-group">
                <label>Percentage</label>
                <input
                  type="number"
                  class="form-control"
                  value={state?.propdesk?.percentage}
                  min={0}
                  onChange={(e) => updatePropdeskState('percentage', e.target.value)}
                  onBlur={validatePercentage}
                  onFocus={() => setState({ percentageError: '', addPropdeskError: '' })}
                  disabled={state.loading}
                />
                {state.percentageError ? <div className='text-danger'>{state.percentageError}</div> : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div class="form-group">
                <label>Status</label>
                <select class="form-select custom-select" aria-label="Default select example" onChange={(e) => updatePropdeskState('status', e.target.value)} disabled={state.loading}>
                  <option value="Up" selected={state?.status === 'Up'}>Up</option>
                  <option value="Down" selected={state?.status === 'Down'}>Down</option>
                </select>
              </div>
            </div>
          </div>
          {state?.editPropdeskError ? <div className="text-center text-danger">{state?.editPropdeskError}</div> : null}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHideModal} disabled={state.loading}>
          Cancel
        </Button>
        <Button color="gleamdesk-blue gleamdesk-orange" onClick={handleSubmit} disabled={state.loading}>
          Update Propdesk
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditPropdesk