import constants from "../constants";
import successHandle from "../../utils/successHandle";
import errorHandle from "../../utils/errorHandle";

const { GET_APPOITMENTS, GET_APPOITMENTS_SUCCESS, GET_APPOITMENTS_ERROR, DELETE_APPOITMENT, DELETE_APPOITMENT_SUCCESS, DELETE_APPOITMENT_ERROR } = constants;
const initialState = { appointments: [], completedAppointments: [], upComingAppointments: [] }

export const appointmentsReducer = (state = initialState, action) => {
  const { type, appointments, message, error, callback } = action;
  switch (type) {
    case GET_APPOITMENTS:
    case DELETE_APPOITMENT:
      return { ...state, loading: true, error: '', message: '' }

    case GET_APPOITMENTS_SUCCESS:
      const upComingAppointments = appointments.filter((appointment) => new Date(appointment.dateTime) > new Date());
      const completedAppointments = appointments.filter((appointment) => new Date(appointment.dateTime) < new Date());
      return { ...state, loading: false, appointments, completedAppointments, upComingAppointments }
    case DELETE_APPOITMENT_SUCCESS:
      return { ...state, loading: false, message: successHandle(message) }

    case GET_APPOITMENTS_ERROR:
    case DELETE_APPOITMENT_ERROR:
      return { ...state, loading: false, error: errorHandle(error) }

    default:
      return state
  }
}
