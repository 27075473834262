import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import authContext from '../../context/authContext/authContext';
import { useDispatch, useSelector } from 'react-redux';
import { editUserAction } from '../../redux/actions/userAction';

const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const MOBILE_NUMBER_REGEX_PATTERN = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;

const UserUpdateModal = ({ isOpen, toggle, userId, users, getUsers }) => {
  const { getConfig } = useContext(authContext)
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.users)

  const intialState = { nameError: '', emailError: '', addressError: '', phoneError: '' }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const onHideModal = () => {
    setState({ ...intialState });
    toggle();
  }

  const closeBtn = () => <button className='btn btn-close' onClick={onHideModal}>X</button>;

  useEffect(() => {
    const user = (users || []).find((user) => user?._id === userId);
    setState({ user: JSON.parse(JSON.stringify(user || null)) })
  }, [userId]);

  const updateUserstate = (key, value) => {
    const { user } = state || {};
    user[key] = value;
    setState({ user })
  }

  const validateEmail = () => {
    const isValidEmail = EMAIL_REGEX_PATTERN.test(state.user.email);
    if (!isValidEmail) {
      setState({ emailError: '*Please enter a valid email!' })
    }
  }

  const validateName = () => {
    if ((state?.user?.name || '').length <= 0) {
      setState({ nameError: '*Please enter a valid name!' })
    }
  }

  const validatePhone = () => {
    if (!state.user.phone || !MOBILE_NUMBER_REGEX_PATTERN.test(state.user.phone)) {
      setState({ phoneError: '*Provide valid indian numbers' })
    }
  }

  const validateAddress = () => {
    if ((state?.user?.name || '').length <= 0) {
      setState({ emailError: '*Please enter a valid address' })
    }
  }

  const handleUpdateUser = (e) => {
    e.preventDefault();
    updateUser();
  };

  const updateUser = () => {
    const update = state.user;
    const config = getConfig();
    dispatch(editUserAction(update, config, getUsers, onHideModal))
  }

  return (
    <Modal isOpen={isOpen} toggle={onHideModal}>
      <ModalHeader toggle={onHideModal} close={closeBtn()}>
        <div className='d-flex align-items-center'>
          <div>Edit User</div>
          <div className='ml-3'>
            {loading ? <div class="spinner-border text-primary" role="status" /> : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleUpdateUser}>
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              class="form-control"
              min={0}
              value={state.user?.name}
              onChange={(e) => updateUserstate('name', e.target.value)}
              onFocus={() => setState({ nameError: intialState.nameError })}
              onBlur={validateName}
              disabled={loading}
            />
            {state.nameError ? <div className='text-danger'>{state.nameError}</div> : null}
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={state.user?.email}
              onChange={(e) => updateUserstate('email', e.target.value)}
              onFocus={() => setState({ emailError: intialState.emailError })}
              onBlur={validateEmail}
              disabled={loading}
            />
            {state.emailError ? <div className='text-danger'>{state.emailError}</div> : null}
          </div>
          <div class="form-group">
            <label for="exampleInputNumber1">Gd Tokens</label>
            <input
              type="number"
              class="form-control"
              id="exampleInputNumber1"
              min={0}
              value={state.user?.gdTokens}
              onChange={(e) => updateUserstate('gdTokens', Number(e.target.value))}
              disabled={loading}
            />
          </div>
          <div class="form-group">
            <label >Phone</label>
            <input
              type="text"
              class="form-control"
              min={0}
              value={state.user?.phone}
              onChange={(e) => updateUserstate('phone', e.target.value)}
              onFocus={() => setState({ phoneError: intialState.phoneError })}
              onBlur={validatePhone}
              disabled={loading}
            />
            {state.phoneError ? <div className='text-danger'>{state.phoneError}</div> : null}
          </div>
          <div class="form-group">
            <label >Address</label>
            <input
              type="text"
              class="form-control"
              min={0}
              value={state.user?.address}
              onChange={(e) => updateUserstate('address', e.target.value)}
              onFocus={() => setState({ addressError: intialState.addressError })}
              onBlur={validateAddress}
              disabled={loading}
            />
            {state.addressError ? <div className='text-danger'>{state.addressError}</div> : null}
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHideModal} disabled={loading}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleUpdateUser} disabled={loading}>
          Update User
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UserUpdateModal