import { applyMiddleware, combineReducers, createStore } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { UsersReducer } from "../reducers/userReducer";
import { appointmentsReducer } from "../reducers/appointmentsReducer";
import { partnerReducer } from "../reducers/partnersReducer";

const rootReducer = combineReducers({
  getNumber: () => 1,
  users: UsersReducer,
  appointments: appointmentsReducer,
  partners: partnerReducer,
});

const middlewares = [thunk];

const middleware = composeWithDevTools(applyMiddleware(...middlewares));

const initalState = { number: { number: 0 } };

const store = createStore(rootReducer, initalState, middleware);

export default store;
