import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import dataContext from './dataContext';

const DataStates = ({ children }) => {
  const getAllowedPages = (pathname) => {
    const pages = ['/', '/dashboard', '/users', '/partners', '/appointments', '/subscribers', '/propdesks', '/stock']
    if (pathname.startsWith('/partner/') && pathname.split('/').length === 3) {
      pages.push(pathname)
    }

    return pages;
  }

  const MONTHS_LIST = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <dataContext.Provider value={{ getAllowedPages, MONTHS_LIST }}>
      {children}
    </dataContext.Provider>
  )
}

export default DataStates;